import React, { useState, useEffect } from "react";
import { fetchExtraordinaryCosts, addExtraordinaryCost, deleteExtraordinaryCost } from "../functions/extraordinaryCosts";
import Confidental from './Confidental.js'
const updateInterval = process.env.REACT_APP_updateInterval || 60000

function ExtraordinaryCosts() {
  const [extraordinaryCosts, setExtraordinaryCosts] = useState([]);
  const [extraordinaryCostsLoaded, setExtraordinaryCostsLoaded] = useState(true);
  const [newCostTitle, setNewCostTitle] = useState("");
  const [newCostPrice, setNewCostPrice] = useState("");

  useEffect(() => {
    handleFetchExtraordinaryCosts()

    const interval = setInterval(() => {
      // Your function logic here
      handleFetchExtraordinaryCosts();
    }, updateInterval); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(interval);
  }, []);

  const handleFetchExtraordinaryCosts = () => {
    fetchExtraordinaryCosts()
      .then(async (data) => {
        if(data.message){
          console.log({"error": data})
        }
        else {
          setExtraordinaryCosts(data)
          setExtraordinaryCostsLoaded(true)
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        // Handle the error accordingly
      });
  }

  const handleAddExtraordinaryCost = (newCostTitle, newCostPrice) => {
    if (isNaN(newCostPrice)) {
      // Alert the user if newCostPrice is not a valid number
      alert("Please enter a valid number for the cost price.");
      return; // Exit the function
    }
    else {
      addExtraordinaryCost(newCostTitle, newCostPrice)
        .then(async () => {
          await handleFetchExtraordinaryCosts();
        })
        .catch(error => {
          console.error("Error fetching data:", error);
          // Handle the error accordingly
        }); 
    }
  }

  const handleDeleteExtraordinaryCost = (id, title, price) => {
    const confirmation = window.confirm(`Do you really want to delete the Extraordinary cost ${title}?`);
    if (confirmation) {
      deleteExtraordinaryCost(id, title, price)
      .then(async () => {
        await handleFetchExtraordinaryCosts()
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        // Handle the error accordingly
      });
    } else {
      // User clicked 'Cancel' or closed the dialog
      // Do nothing or handle the cancel action
    }
  }

  const calculateTotalExtraordinaryCosts = (extraordinaryCosts) => {
    let total = 0;
    extraordinaryCosts.forEach(cost => {
      total += parseFloat(cost.price); // Parse the price as a float
    });
    return total;
  };

  return (
    <div className="wrapper">
      <div className="panel fullHeightMinus80Padding">
        <div className="panelContent">
          <h2>Extraordinary Costs Table</h2>
          <input
            type="text"
            value={newCostTitle}
            onChange={(e) => setNewCostTitle(e.target.value)}
            placeholder="Title"
          />
          <input
            type="text"
            value={newCostPrice}
            onChange={(e) => setNewCostPrice(e.target.value)}
            placeholder="Price"
          />
          <button className="buttonTransparent" onClick={(e) => handleAddExtraordinaryCost(newCostTitle, newCostPrice)}>Add Cost</button>
          {extraordinaryCostsLoaded && extraordinaryCosts.map ?
            <>
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {extraordinaryCosts.map((cost) => (
                    <tr key={cost.id}>
                      <td>{cost.id}</td>
                      <td>{cost.title}</td>
                      <Confidental hide={localStorage.getItem("hideConfidental") == "true"}><td>{cost.price}</td>
                      <td>€</td></Confidental>
                      <td>
                        <button className="buttonTransparent" onClick={() => handleDeleteExtraordinaryCost(cost.id, cost.title, cost.price)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                  {/*
                    <tr style={{"borderTop": "2px solid #ffffff"}}>
                      <td></td>
                      <td>Total</td>
                      <Confidental hide={localStorage.getItem("hideConfidental") == "true"}><td className="fontColorRed">{calculateTotalExtraordinaryCosts(extraordinaryCosts)}</td>
                      <td className="fontColorRed">€</td></Confidental>
                    </tr>
                  */}
                </tbody>
              </table>

              <div className="panel  centerFlex flexDirectionColumn totalPrice">
                &nbsp;
                <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                  <h3 className="redProjectTotalCosts">{calculateTotalExtraordinaryCosts(extraordinaryCosts)}€ </h3>
                </Confidental>
                &nbsp;
                <h3>Total</h3>
                &nbsp;
              </div>
            </>
          :
            null
          }
        </div>
      </div>
    </div>
  );
}

export default ExtraordinaryCosts;
