import ExtraordinaryCosts from '../components/ExtraordinaryCosts';
import FixedCosts from '../components/FixedCosts';
import IncomeOverview from '../components/IncomeOverview';
import OtherIncome from '../components/OtherIncome';
import PurchasePlanning from '../components/PurchasePlannings';
import TabsComponent from '../components/TabsComponent';
import TotalCostsOverview from '../components/TotalCostsOverview';
import TodoList from '../components/TodoList';

function IncomeOverviewSite() {
  return (
    <div className="incomeOverviewContainer">
      <div className="flexDirectionColumn">
        <div className="flexDirectionRow">
          <IncomeOverview showFilter={true} />
          <OtherIncome />
          <FixedCosts />
          <ExtraordinaryCosts />
          <TabsComponent tabComponents={[{name: "Purchase Planning", component: <PurchasePlanning/>}, {name: "Todo List", component: <TodoList/>}]} />
        </div>
        <div className="flexDirectionColumn">
          <TotalCostsOverview />
        </div>
      </div>
    </div>
  );
}

export default IncomeOverviewSite;
