import React, { useState, useEffect } from 'react';
import i18n from './i18n.js';
import { Link } from "react-router-dom";

const defaultConfig = require("../configs/defaultConfig")

const t = i18n.t;

const Menu = () => {
  const [date, setDate] = useState(new Date().toLocaleDateString()); // Initialize state with current date and time
  const [time, setTime] = useState(new Date().toLocaleTimeString()); // Initialize state with current date and time
  const [hideConfidental, setHideConfidental] = useState(localStorage.getItem("hideConfidental") == "true");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setDate(now.toLocaleDateString()); // Update state with current date and time
      setTime(now.toLocaleTimeString()); // Update state with current date and time
    }, 1000); // Update every 1000 milliseconds (1 second)

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once after the component mounts

  const handleSetHideConfidental = () => {
    if(localStorage.getItem("hideConfidental") == "true") {
      localStorage.setItem("hideConfidental", "false")
    }
    else {
      localStorage.setItem("hideConfidental", "true")
    }

    window.location.reload();
  }


  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/")
  }

  const handleLogin = () => {
    window.location.replace("/login")
  }


  return (
    <>
      <center>
        <div className="panel menu">
          <div className="panelContent">
            <h1>Income Watcher</h1>
            <h2>{date}&nbsp;&nbsp;{time}</h2>
            <br/>
            <button className="buttonTransparent" onClick={() => handleLogin()}>{t("Login")}</button>
            &nbsp;
            &nbsp;
            <button className="buttonTransparent" onClick={() => handleLogout()}>{t("Logout")}</button>
            &nbsp;
            &nbsp;
            <button className="buttonRed" onClick={(e) => handleSetHideConfidental()}>{hideConfidental ? "Show" : "Hide"} Confidental</button>
          </div>
          
          </div>
      </center>
    </>
  );
};

export default Menu;
