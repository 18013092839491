import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function TabsComponent({ tabComponents }) {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [dataIsLoaded, setDataIsLoaded] = useState(false);

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    const localStorageCurrentTabIndex = await localStorage.getItem("currentTabIndex")
    await setCurrentTabIndex(localStorageCurrentTabIndex)
    await setDataIsLoaded(true)
  }

  const handleSelectTab = (index) => {
    setCurrentTabIndex(index)
    localStorage.setItem("currentTabIndex", index)
  }

  return (
    <div className="panelNoPadding">
        {dataIsLoaded && 
            <Tabs selectedIndex={parseInt(currentTabIndex)} onSelect={(index) => handleSelectTab(index)}>
                <TabList>
                    {tabComponents.map((component, index) => (
                        <Tab key={index}>{component.name}</Tab>
                    ))}
                </TabList>

                {tabComponents.map((component, index) => (
                    <TabPanel key={index}>
                        {component.component}
                    </TabPanel>
                ))}
            </Tabs>
        }
    </div>
  );
}

export default TabsComponent;
