const getCurrentMonthRange = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
    const formatForInput = (date, isEndOfMonth = false) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = isEndOfMonth ? '23' : String(date.getHours()).padStart(2, '0');
      const minutes = isEndOfMonth ? '59' : String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
  
    return [formatForInput(startOfMonth), formatForInput(endOfMonth, true)];
  };

  export default getCurrentMonthRange