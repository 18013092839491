import React from 'react';
import Menu from './Menu'; // Import the Menu component here

const Layout = ({ children }) => {
  return (
    <div>
      <Menu /> {/* Include the Menu component */}
      <div className="content">
        {children} {/* Render children components */}
      </div>
    </div>
  );
};

export default Layout;
