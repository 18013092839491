const Confidental = ({children, hide}) => {
    if(hide){
        return (
        <div className="confidental">
            {children}
        </div>
        )
    }
    else {
        return children
    }
}

export default Confidental