async function calculateTotalProjectCost(data) {
    const projectMap = new Map();
  
    // Iterate through each entry in the data
    data.forEach(entry => {
        const { project_id, projectTitle, client_id, client_name, start_time, end_time, price, billingType } = entry;
        // Calculate duration in hours
        const startTime = new Date(start_time);
        const endTime = new Date(end_time);
        const durationHours = (endTime - startTime) / (1000 * 60 * 60);
        let totalPrice = ""
        // Calculate total price
        if(entry.billingType == "per Hour"){
          totalPrice = durationHours * price;
        }
        else if(entry.billingType == "per Task"){
          totalPrice = price;
        }
        else {
          return("Billing Type not Supported. Check Code, its Hardcoded fuckerisch")
        }
  
        // Update total price for the project
        if (projectMap.has(project_id)) {
            const existingProject = projectMap.get(project_id);
            existingProject.totalCosts += totalPrice;
            projectMap.set(project_id, existingProject);
        } else {
            projectMap.set(project_id, {
                project_id: project_id,
                project_name: projectTitle,
                client_id: client_id,
                client_name: client_name,
                totalCosts: totalPrice,
                pricePerHour: price,
                billingType: billingType
            });
        }
    });
  
    return Array.from(projectMap.values());
  }

  export default calculateTotalProjectCost