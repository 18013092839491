const config = require("../configs/defaultConfig");
const apiToken = localStorage.getItem("apiToken")
const username = localStorage.getItem("username")
const apiUrl = process.env.REACT_APP_apiUrl

export const fetchExtraordinaryCosts = async () => {
  try {
    const response = await fetch(`${apiUrl}/extraordinarycosts/`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      }
    });
    const data = await response.json();
    if (data.error) {
      return data.error;
    } else {
      return data;
    }
  } catch (error) {
    console.error("Error fetching extraordinary costs:", error);
    return error;
  }
};

export const addExtraordinaryCost = async (newCostTitle, newCostPrice) => {
  try {
    await fetch(`${apiUrl}/extraordinarycosts/create`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      },
      body: JSON.stringify({ 
        title: newCostTitle,
        price: newCostPrice 
      }),
    });
    // No need to refresh data here since it's handled after adding
  } catch (error) {
    console.error("Error adding extraordinary cost:", error);
    throw error;
  }
};

export const deleteExtraordinaryCost = async (id, title, price) => {
  try {
    await fetch(`${apiUrl}/extraordinarycosts/delete`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      },
      body: JSON.stringify({ 
        "id": id,
        "title": title,
        "price": price     
      }),
    });
    // No need to refresh data here since it's handled after deleting
  } catch (error) {
    console.error("Error deleting extraordinary cost:", error);
    throw error;
  }
};
