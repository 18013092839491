import '../general.css';
import { useEffect, useState, useRef } from "react";
import i18n from '../components/i18n.js';
import HaloComponent from '../components/HaloComponent.js';
import Login from "../components/Login.js"

const t = i18n.t;

const branding = require("../configs/branding.json")

function LoginSite() {

  const haloRef = useRef(null);
  const [unlockRequests, setUnlockRequests] = useState([]);

  // Callback function to get the value from UnlockRequests

  return (
    <>
      <Login />
    </>
  );
}

export default LoginSite;
