import '../general.css';
import { useEffect, useState, useRef } from "react";
import i18n from './i18n.js';
import ToggleButton from 'terra-toggle-button';
import getRiseData from '../functions/getRiseData.js'
import getCurrentMonthRange from '../functions/getCurrentMonthRange.js'
import calculateTotalProjectCost from '../functions/calculateTotalProjectCost.js'
import Confidental from './Confidental.js'
const updateInterval = process.env.REACT_APP_updateInterval || 60000

const t = i18n.t;

const branding = require("../configs/branding.json")
const config = require("../configs/defaultConfig")
const apiUrl = config.apiUrl
const apiToken = config.apiToken || "123"

function IncomeOverview({showFilter}) {

  const haloRef = useRef(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [projectTotalCosts, setProjectTotalCosts] = useState(null);
  const [projectTotalCostsLoaded, setProjectTotalCostsLoaded] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [error, setError] = useState(null);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  

  
  // Callback function to get the value from UnlockRequests

  const handleOnClick = async () => {
    getRiseData(startTime, endTime, projectType)
    .then(async (data) => {
      // Use the fetched data
      // Calculate total cost for each project
      calculateTotalProjectCost(data)
        .then(projectTotalCosts => {
          setProjectTotalCosts(projectTotalCosts)
          setProjectTotalCostsLoaded(true)

          console.log("projectTotalCosts")
          console.log(projectTotalCosts)
        })
        .catch(error => {
            console.error("Error calculating total cost:", error);
            // Handle the error accordingly
        })
      })
    .catch(error => {
      console.error("Error fetching data:", error);
      // Handle the error accordingly
    });
  }

  useEffect(() => {
    getAllData();

    const interval = setInterval(() => {
      // Your function logic here
      getAllData();
    }, updateInterval); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(interval);
  }, [localStorage.getItem("hideConfidental") == "true"]);


  const getAllData = () => {
    const [defaultStartTime, defaultEndTime] = getCurrentMonthRange();
    setStartTime(defaultStartTime);
    setEndTime(defaultEndTime);
    
    getRiseData(defaultStartTime, defaultEndTime, "client_project")
      .then(async (data) => {
        // Use the fetched data
        // Calculate total cost for each project
        setProjectData(data)
        calculateTotalProjectCost(data)
          .then(projectTotalCosts => {
            setProjectTotalCosts(projectTotalCosts)
            setProjectTotalCostsLoaded(true)
          })
          .catch(error => {
              console.error("Error calculating total cost:", error);
              // Handle the error accordingly
          })
      .catch(error => {
        console.error("Error fetching data:", error);
        // Handle the error accordingly
      });
    });
  }

  return (
    <>
      <div className="wrapper panel" ref={haloRef}>
        {showFilter ?
          <div className="panel">
            <input type="datetime-local" placeholder="Start Time" value={startTime} onChange={(e) => setStartTime(e.target.value)}></input>
            <input type="datetime-local" placeholder="End Time" value={endTime} onChange={(e) => setEndTime(e.target.value)}></input>
            <select id="options" value={projectType} onChange={(e) => setProjectType(e.target.value)}>
              <option value="client_project">Client Projects</option>
              <option value="internal_project">Internal Projects</option>
            </select>
            &nbsp;
            &nbsp;
            <button className="buttonTransparent" onClick={(e) => handleOnClick(e)}>Filter</button>
          </div>
        :
          null
        }
        {projectTotalCostsLoaded &&
          <ToggleButton className="toggleButton" style={{"marginLeft": "20px"}} isAnimated closedButtonText={t("Show Table")}>
            <div className="panel">
              <h1>Data from MySQL</h1>
              <table>
                <thead>
                  <tr>
                    <th>Project ID</th>
                    <th>User ID</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Status</th>
                    <th>Price</th>
                    <th>Duration (seconds)</th>
                    <th>Entry Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {projectData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.project_id}</td>
                      <td>{item.user_id}</td>
                      <td>{new Date(item.start_time).toLocaleString()}</td>
                      <td>{new Date(item.end_time).toLocaleString()}</td>
                      <td>{item.status}</td>
                      <td><Confidental hide={localStorage.getItem("hideConfidental") == "true"}>{item.price}</Confidental></td>
                      <td>{item.duration_seconds}</td>
                      <td><Confidental hide={localStorage.getItem("hideConfidental") == "true"}>{(item.price * (item.duration_seconds / 60 / 60)).toFixed(2)}</Confidental></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ToggleButton>
        }

        {projectTotalCostsLoaded &&
          <>
            <div className="flexContainer4x4">
              {projectTotalCosts.map((project) => (
                  <div class="panel flexContainer4x4Item flexDirectionColumn">
                    <div class="panelContent">
                        <div class="tagPurple"><Confidental hide={localStorage.getItem("hideConfidental") == "true"}>{project.client_name}</Confidental></div>
                        <h2>{project.project_name}</h2>
                    </div>
                    <div class="panelFooter">
                        <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                            <h3 class="projectTotalCosts alignBottom">{project.totalCosts.toFixed(2)} €</h3>
                        </Confidental>  
                      {project.billingType}: <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>{project.pricePerHour} €</Confidental>
                    </div>
                  </div>
              ))}

              
              <div className="panel fullWidth centerFlex flexDirectionColumn">
                &nbsp;
                <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                  <h3 className="greenProjectTotalCosts">{projectTotalCosts.reduce((acc, curr) => acc + curr.totalCosts, 0).toFixed(2)} €</h3>
                </Confidental>
                  &nbsp;
                <h3>Total</h3>
                &nbsp;
              </div>
            </div>
          </>
        }
      </div>

    </>
  );
}

export default IncomeOverview;
