import React, { useState, useEffect } from "react";
import { fetchFixedCosts, addFixedCost, deleteFixedCost } from "../functions/fixedCosts";
import Confidental from './Confidental.js'
const updateInterval = process.env.REACT_APP_updateInterval || 60000

function FixedCosts() {
  const [fixedCosts, setFixedCosts] = useState([]);
  const [fixedCostsLoaded, setFixedCostsLoaded] = useState(true);
  const [newCostTitle, setNewCostTitle] = useState("");
  const [newCostPrice, setNewCostPrice] = useState("");

  useEffect(() => {
    handleFetchFixedCosts()

    const interval = setInterval(() => {
      // Your function logic here
      handleFetchFixedCosts();
    }, updateInterval); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(interval);
  }, []);

  const handleFetchFixedCosts = () => {
    fetchFixedCosts()
      .then(async (data) => {
        if(data.message){
          console.log({"error": data})
        }
        else {
          setFixedCosts(data)
          setFixedCostsLoaded(true)
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        // Handle the error accordingly
      });
  }

 
  const handleAddFixedCost = (newCostTitle, newCostPrice) => {
    if (isNaN(newCostPrice)) {
      // Alert the user if newCostPrice is not a valid number
      alert("Please enter a valid number for the cost price.");
      return; // Exit the function
    }
    else {
      addFixedCost(newCostTitle, newCostPrice)
        .then(async () => {
          await handleFetchFixedCosts();
        })
        .catch(error => {
          console.error("Error fetching data:", error);
          // Handle the error accordingly
        }); 
    }
  }
 
  const handleDeleteFixedCost = (id, title, price) => {
    const confirmation = window.confirm(`Do you really want to delete the Fixedcost ${title}?`);
    if (confirmation) {
      deleteFixedCost(id, title, price)
      .then(async () => {
        await handleFetchFixedCosts()
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        // Handle the error accordingly
      });
    } else {
      // User clicked 'Cancel' or closed the dialog
      // Do nothing or handle the cancel action
    }
  }

  const calculateTotalFixedCosts = (fixedCosts) => {
    let total = 0;
    fixedCosts.forEach(cost => {
      total += parseFloat(cost.price); // Parse the price as a float
    });
    return total;
  };

  return (
    <div className="wrapper">
      <div className="panel fullHeightMinus80Padding">
        <div className="panelContent">
          <h2>Fixed Costs Table</h2>
          <input
            type="text"
            value={newCostTitle}
            onChange={(e) => setNewCostTitle(e.target.value)}
            placeholder="Title"
          />
          <input
            type="text"
            value={newCostPrice}
            onChange={(e) => setNewCostPrice(e.target.value)}
            placeholder="Price"
          />
          <button className="buttonTransparent" onClick={(e) => handleAddFixedCost(newCostTitle, newCostPrice)}>Add Cost</button>
          {fixedCostsLoaded && fixedCosts.map ?
          <>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {fixedCosts.map((cost) => (
                  <tr key={cost.id}>
                    <td>{cost.id}</td>
                    <td>{cost.title}</td>
                    <Confidental hide={localStorage.getItem("hideConfidental") == "true"}><td>{cost.price}</td>
                    <td>€</td></Confidental>
                    <td>
                      <button className="buttonTransparent" onClick={() => handleDeleteFixedCost(cost.id, cost.title, cost.price)}>Delete</button>
                    </td>
                  </tr>
                ))}
                {/*
                  <tr style={{"borderTop": "2px solid #ffffff"}}>
                    <td></td>
                    <td>Total</td>
                    <Confidental hide={localStorage.getItem("hideConfidental") == "true"}><td>{calculateTotalFixedCosts(fixedCosts)}</td>
                    <td>€</td></Confidental>
                  </tr>
                */}
              </tbody>
            </table>

            <div className="panel  centerFlex flexDirectionColumn totalPrice">
              &nbsp;
              <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                <h3 className="redProjectTotalCosts">{calculateTotalFixedCosts(fixedCosts)} €</h3>
              </Confidental>
              &nbsp;
              <h3>Total</h3>
              &nbsp;
            </div>
        </>
          :
            null
          }
        </div>
      </div>
    </div>
  );
}

export default FixedCosts;
