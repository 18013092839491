import React, { useState, useEffect } from "react";
import { fetchPurchasePlanning, addPurchasePlanning, deletePurchasePlanning } from "../functions/purchasePlannings.js";
import Confidental from "./Confidental.js";

const updateInterval = process.env.REACT_APP_updateInterval || 60000

function PurchasePlanning() {
  const [purchasePlanning, setPurchasePlanning] = useState([]);
  const [newPlanningTitle, setNewPlanningTitle] = useState("");
  const [newPlanningAmount, setNewPlanningAmount] = useState("");

  useEffect(() => {
    fetchPurchasePlanningData();

    const interval = setInterval(() => {
      // Your function logic here
      fetchPurchasePlanningData();
    }, updateInterval); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(interval);
  }, []);

  const fetchPurchasePlanningData = () => {
    fetchPurchasePlanning()
      .then(data => {
        setPurchasePlanning(data);
      })
      .catch(error => {
        console.error("Error fetching purchase planning data:", error);
      });
  };

  const handleAddPurchasePlanning = () => {
    if (isNaN(newPlanningAmount)) {
      alert("Please enter a valid number for the planning amount.");
      return;
    }
    addPurchasePlanning(newPlanningTitle, newPlanningAmount)
      .then(() => {
        fetchPurchasePlanningData();
        setNewPlanningTitle("");
        setNewPlanningAmount("");
      })
      .catch(error => {
        console.error("Error adding purchase planning:", error);
      });
  };

  const handleDeletePurchasePlanning = (id) => {
    const confirmation = window.confirm("Are you sure you want to delete this planning?");
    if (confirmation) {
      deletePurchasePlanning(id)
        .then(() => {
          fetchPurchasePlanningData();
        })
        .catch(error => {
          console.error("Error deleting purchase planning:", error);
        });
    }
  };

  const calculateTotalPurchasePlanning = (planning) => {
    let total = 0;
    planning.forEach(planning => {
      total += parseFloat(planning.amount); // Parse the price as a float
    });
    return total;
  };

  return (
    <div className="wrapper">
      <div className="panelNoBorderNoMargin fullHeightMinus80Padding">
        <div className="panelContent">
          <h2>Purchase Planning</h2>
          <input
            type="text"
            value={newPlanningTitle}
            onChange={(e) => setNewPlanningTitle(e.target.value)}
            placeholder="Title"
          />
          <input
            type="text"
            value={newPlanningAmount}
            onChange={(e) => setNewPlanningAmount(e.target.value)}
            placeholder="Amount"
          />
          <button className="buttonTransparent" onClick={handleAddPurchasePlanning}>Add Planning</button>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {purchasePlanning.map(planning => (
                <tr key={planning.id}>
                  <td>{planning.id}</td>
                  <td>{planning.title}</td>
                  <td>{planning.amount}</td>
                  <td>
                    <button className="buttonTransparent" onClick={() => handleDeletePurchasePlanning(planning.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>


          <div className="panel  centerFlex flexDirectionColumn totalPrice">
              &nbsp;
              <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                <h3 className="yellowProjectTotalCosts">{calculateTotalPurchasePlanning(purchasePlanning)}€ </h3>
              </Confidental>
              &nbsp;
              <h3>Total</h3>
              &nbsp;
            </div>
        </div>
      </div>
    </div>
  );
}

export default PurchasePlanning;
