const defaultConfig = require("../configs/defaultConfig")
const apiUrl = process.env.REACT_APP_apiUrl
const apiToken = defaultConfig.loginApiToken
const username = localStorage.getItem("username")

export default async function login(username, hashedPassword) {
    return await fetch(`${apiUrl}/login`, {
        method: 'POST', // or 'PUT'
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiToken}`,
            'Username': `${username}`,
            "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
        },
        body: JSON.stringify({
            "username": username,
            "hashedPassword": hashedPassword
        })
    })
    .then((res) => {
        if ( res.status != "200") {
            throw res.statusText
        }
        else {
            return res.json()
        }
    })
    .then((json) => {
        //json to array for every object in object enVariables
        if(json.apiToken) {
            return(json.apiToken)
        }
        else if (json.isValid == false) {
            alert("Username or Password wrong")
            return("wrongUsernamePassword")
        }
    })
    .catch( err => {
        return(err)
    })
}