// DeviceOrientationGraphic.js
import React, { useState, useEffect } from 'react';
import i18n from './i18n.js';
import hashPassword from '../functions/hashPassword.js';
import login from '../functions/login.js';

const defaultConfig = require("../configs/defaultConfig")
const backgroundImage = require("../media/bgLogin.jpg")
const t = i18n.t;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    const passwordHash = await hashPassword(password);

    login(username, passwordHash)
    .then((result) => {
      console.log(result);
      if(result !== "wrongUsernamePassword"){
        localStorage.setItem('apiToken', result);
        localStorage.setItem('username', username);
        window.location.href = '/';
      }
      // Do something with the result, if needed
    })
    .catch((error) => {
      console.error(error);
      // Handle errors if necessary
    });


  };
  return (
    <>
      <div style={{
        "display": 'flex',
        "justifyContent": 'center',
        "alignItems": 'center',
        "height": '100vh',
        "background": `url(${backgroundImage}) center center / cover no-repeat`,        
      }}>
        <div className="panel">
        <div className="panelHeader centerFlex">
          <h1>incomeWatcher Login</h1>
        </div>
        <div className="panelContent">
          <div className="flexDirectionColumn">
              <input style={{"padding": "10px", "fontSize": "20px", "margin-left": "0px", "margin-right": "0px", "margin-top": "10px", "margin-bottom": "10px"}} onChange={(e) => setUsername(e.target.value)} placeholder={t("Username")} />
              <input style={{"padding": "10px", "fontSize": "20px", "margin-left": "0px", "margin-right": "0px", "margin-top": "10px", "margin-bottom": "10px"}} type="password" onChange={(e) => setPassword(e.target.value)} placeholder={t("Password")} />
              <button style={{"padding": "10px", "fontSize": "20px", "margin-left": "0px", "margin-right": "0px", "margin-top": "10px", "margin-bottom": "10px"}} className="buttonTransparent" onClick={(e) => handleLogin(e)}>{t("Login")}</button>
            </div>
          </div>
          <div className="panelContent">
          {error}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
