const config = require("../configs/defaultConfig");
const apiToken = localStorage.getItem("apiToken");
const username = localStorage.getItem("username");
const apiUrl = process.env.REACT_APP_apiUrl

export const fetchPurchasePlanning = async () => {
  try {
    const response = await fetch(`${apiUrl}/purchaseplanning/`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      }
    });
    const data = await response.json();
    if (data.error) {
      return data.error;
    } else {
      return data;
    }
  } catch (error) {
    console.error("Error fetching purchase planning:", error);
    throw error;
  }
};

export const addPurchasePlanning = async (title, amount) => {
  try {
    await fetch(`${apiUrl}/purchaseplanning/create`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      },
      body: JSON.stringify({ 
        title: title,
        amount: amount 
      }),
    });
  } catch (error) {
    console.error("Error adding purchase planning:", error);
    throw error;
  }
};

export const deletePurchasePlanning = async (id) => {
  try {
    await fetch(`${apiUrl}/purchaseplanning/delete`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      },
      body: JSON.stringify({ 
        id: id
      }),
    });
  } catch (error) {
    console.error("Error deleting purchase planning:", error);
    throw error;
  }
};
