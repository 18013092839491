import React, { useEffect, useRef } from 'react';
import * as VANTA from 'vanta';
import HALO from 'vanta/dist/vanta.halo.min';
import * as THREE from 'three'

const HaloComponent = ({ forwardedRef, baseColor="#00ff00", backgroundColor="#000000", amplitudeFactor=0, speed=0 }) => {
  useEffect(() => {
    if (!forwardedRef.current) return;

    const haloEffect = HALO({
      el: forwardedRef.current,
      mouseControls: true,
      touchControls: true,
      gyroControls: true,
      minHeight: 200.00,
      minWidth: 200.00,
      THREE: THREE,
      baseColor: baseColor,
      backgroundColor: backgroundColor,
      amplitudeFactor: parseInt(amplitudeFactor),
      xOffset: 0,
      yOffset: 0,
      size: 1.00,
      speed: parseInt(speed)
    });

    // Cleanup effect when the component unmounts
    return () => {
      if (haloEffect) haloEffect.destroy();
    };
  }, []); // Empty dependency array ensures the effect runs once on mount

};

export default HaloComponent;
