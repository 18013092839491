const config = require("../configs/defaultConfig")
const apiToken = localStorage.getItem("apiToken")
const username = localStorage.getItem("username")
const apiUrl = process.env.REACT_APP_apiUrl


const getRiseData = async (filterStartTime, filterEndTime, filterProjectType) => {
    return await fetch(`${apiUrl}/rise/income/`, {
      method: 'POST', // or 'PUT'
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${apiToken}`,
          'Username': `${username}`,
          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      },
      body: JSON.stringify({
          "startTime": filterStartTime,
          "endTime": filterEndTime,
          "projectType": filterProjectType,
      })
    })
    .then((res) => {
            return res.json()
    })
    .then((json) => {
        //json to array for every object in object enVariables
        if(json.error){
            return(json.error)
        }
        else {
          return json
        }
    })
    .catch( err => {
      return(err)
    })
  };


  export default getRiseData;