import React, { useState, useEffect } from "react";
import { fetchTodoList, addTodoList, deleteTodoList } from "../functions/todoList.js";
import Confidental from "./Confidental.js";

const updateInterval = process.env.REACT_APP_updateInterval || 60000

function TodoList() {
  const [toDoList, setTodoList] = useState([]);
  const [newTodoTitle, setNewTodoTitle] = useState("");
  const [newTodoDescription, setNewTodoDescription] = useState("");

  useEffect(() => {
    fetchTodoListData();

    const interval = setInterval(() => {
      // Your function logic here
      fetchTodoListData();
    }, updateInterval); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(interval);
  }, []);

  const fetchTodoListData = () => {
    fetchTodoList()
      .then(data => {
        setTodoList(data);
      })
      .catch(error => {
        console.error("Error fetching purchase planning data:", error);
      });
  };

  const handleAddTodoList = () => {
    addTodoList(newTodoTitle, newTodoDescription)
      .then(() => {
        fetchTodoListData();
        setNewTodoTitle("");
        setNewTodoDescription("");
      })
      .catch(error => {
        console.error("Error adding purchase planning:", error);
      });
  };

  const handleDeleteTodoList = (id) => {
    const confirmation = window.confirm("Are you sure you want to delete this planning?");
    if (confirmation) {
      deleteTodoList(id)
        .then(() => {
          fetchTodoListData();
        })
        .catch(error => {
          console.error("Error deleting purchase planning:", error);
        });
    }
  };


  return (
    <div className="wrapper">
      <div className="panelNoBorderNoMargin fullHeightMinus80Padding">
        <div className="panelContent">
          <h2>Todo List</h2>
          <input
            type="text"
            value={newTodoTitle}
            onChange={(e) => setNewTodoTitle(e.target.value)}
            placeholder="Title"
          />
          <input
            type="text"
            value={newTodoDescription}
            onChange={(e) => setNewTodoDescription(e.target.value)}
            placeholder="Description"
          />
          <button className="buttonTransparent" onClick={handleAddTodoList}>Add Todo</button>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {toDoList.map(todo => (
                <tr key={todo.id}>
                  <td>{todo.id}</td>
                  <td><Confidental>{todo.title}</Confidental></td>
                  <td><Confidental>{todo.description}</Confidental></td>
                  <td>
                    <button className="buttonTransparent" onClick={() => handleDeleteTodoList(todo.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TodoList;
