import bcrypt from 'bcryptjs';
const sha256 = require('js-sha256')

const hashPasswordSalt = "$2a$12$VWKR7OxgU5m55czaaTjb0.zQHL28nxUYk1H83dP4qts9cSPZlNGPa";
const sha256Secret = process.env.sha256Secret || "e41cc1c2d1a14b98e9987618db5cdda61247d7a3fe147828a6f8522bca155f6ee219056a6645394ac900877a3768bb4d4a5f9e13cedbf84f1a9ffb2fb0a0a0d8"

export default function hashPassword(password) {
    try {
      // Hash the password with the salt
      // const hashed = bcrypt.hashSync(password, hashPasswordSalt);
      const hashed = sha256.hmac(sha256Secret, password)
      return(hashed);
    } catch (error) {
      console.error('Error hashing password:', error);
    }
};