const config = require("../configs/defaultConfig");
const apiToken = localStorage.getItem("apiToken")
const username = localStorage.getItem("username")
const apiUrl = process.env.REACT_APP_apiUrl

export const fetchOtherIncome = async () => {
  try {
    console.log(config.apiUrl)
    const response = await fetch(`${apiUrl}/otherincome/`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept, Username",
      }
    });
    const data = await response.json();
    if (data.error) {
      return data.error;
    } else {
      return data;
    }
  } catch (error) {
    console.error("Error fetching other income:", error);
    throw error;
  }
};

export const addOtherIncome = async (title, amount) => {
  try {
    await fetch(`${apiUrl}/otherincome/create`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      },
      body: JSON.stringify({ 
        title: title,
        amount: amount 
      }),
    });
  } catch (error) {
    console.error("Error adding other income:", error);
    throw error;
  }
};

export const deleteOtherIncome = async (id) => {
  try {
    await fetch(`${apiUrl}/otherincome/delete`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiToken}`,
        'Username': `${username}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      },
      body: JSON.stringify({ 
        id: id
      }),
    });
  } catch (error) {
    console.error("Error deleting other income:", error);
    throw error;
  }
};
