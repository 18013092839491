import React, { useState, useEffect } from "react";
import { fetchFixedCosts, addFixedCost, deleteFixedCost } from "../functions/fixedCosts";
import Confidental from './Confidental.js'
import getRiseData from '../functions/getRiseData.js'
import calculateTotalProjectCost from '../functions/calculateTotalProjectCost.js'
import getCurrentMonthRange from '../functions/getCurrentMonthRange.js'
import { fetchExtraordinaryCosts, addExtraordinaryCost, deleteExtraordinaryCost } from "../functions/extraordinaryCosts";
import { fetchOtherIncome, addOtherIncome, deleteOtherIncome } from "../functions/otherIncome";
const updateInterval = process.env.REACT_APP_updateInterval || 60000

function TotalCostsOverview() {
  const [projectTotalCosts, setProjectTotalCosts] = useState(null);
  const [projectTotalCostsLoaded, setProjectTotalCostsLoaded] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [projectType, setProjectType] = useState(null);

  const [fixedCosts, setFixedCosts] = useState([]);
  const [fixedCostsLoaded, setFixedCostsLoaded] = useState(true);
  
  const [extraordinaryCosts, setExtraordinaryCosts] = useState([]);
  const [extraordinaryCostsLoaded, setExtraordinaryCostsLoaded] = useState(true);
  
  const [totalCosts, setTotalCosts] = useState([]);
  const [totalCostsLoaded, setTotalCostsLoaded] = useState(true);
  
  const [otherIncomes, setOtherIncomes] = useState([]);
  const [otherIncomesLoaded, setOtherIncomesLoaded] = useState([]);

  const [totalOtherIncomes, setTotalOtherIncomes] = useState([]);


  useEffect(() => {
    getAllData()
    
    const interval = setInterval(() => {
      // Your function logic here
      getAllData();
    }, updateInterval); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(interval);
  }, []);

  const getAllData = async () => {
    const [defaultStartTime, defaultEndTime] = getCurrentMonthRange();
    setStartTime(defaultStartTime);
    setEndTime(defaultEndTime);
    let fetchedProjectTotalCosts
    let fetchedFixedCosts
    let fetchedExtraordinaryCosts
    let fetchedOtherIncomes
    
    await getRiseData(defaultStartTime, defaultEndTime, projectType)
    .then(async (data) => {
      // Use the fetched data
      // Calculate total cost for each project
      calculateTotalProjectCost(data)
        .then(projectTotalCosts => {
          setProjectTotalCosts(projectTotalCosts)
          setProjectTotalCostsLoaded(true)
          fetchedProjectTotalCosts = projectTotalCosts
        })
        .catch(error => {
            console.error("Error calculating total cost:", error);
            // Handle the error accordingly
        })
      })
    .catch(error => {
      console.error("Error fetching data:", error);
      // Handle the error accordingly
    });

    await fetchFixedCosts()
    .then(async (data) => {
      if(data.message){
        console.log({"error": data})
      }
      else {
        setFixedCosts(data)
        setFixedCostsLoaded(true)
        fetchedFixedCosts = data
      }
    })
    .catch(error => {
      console.error("Error fetching data:", error);
      // Handle the error accordingly
    });

    await fetchExtraordinaryCosts()
      .then(async (data) => {
        if(data.message){
          console.log({"error": data})
        }
        else {
          setExtraordinaryCosts(data)
          setExtraordinaryCostsLoaded(true)
          fetchedExtraordinaryCosts = data
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        // Handle the error accordingly
      });

    await fetchOtherIncome()
      .then(async (data) => {
        if(data.message){
          console.log({"error": data})
        }
        else {
          setOtherIncomes(data)
          setOtherIncomesLoaded(true)
          fetchedOtherIncomes = data
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        // Handle the error accordingly
      });

      calculateTotalCosts(fetchedProjectTotalCosts, fetchedOtherIncomes, fetchedFixedCosts, fetchedExtraordinaryCosts)
  }

  const calculateTotalProjectCosts = (projectCosts) => {
    let total = 0;
    projectCosts.forEach(cost => {
      total += parseFloat(cost.price); // Parse the price as a float
    });
    return total;
  };

  const calculateTotalFixedCosts = (fixedCosts) => {
    let total = 0;
    fixedCosts.forEach(cost => {
      total += parseFloat(cost.price); // Parse the price as a float
    });
    return total;
  };

  const calculateTotalExtraordinaryCosts = (extraordinaryCosts) => {
    let total = 0;
    extraordinaryCosts.forEach(cost => {
      total += parseFloat(cost.price); // Parse the price as a float
    });
    return total;
  };
  
  const calculateTotalCosts = (fetchedProjectTotalCosts, fetchedOtherIncome, fetchedFixedCosts, fetchedExtraordinaryCosts) => {
    if(fetchedProjectTotalCosts) {
      const calculatedProjectTotalCosts = parseFloat(fetchedProjectTotalCosts.reduce((acc, curr) => acc + curr.totalCosts, 0).toFixed(2))
      const calculatedOtherIncome = calculateTotalOtherIncomes(fetchedOtherIncome)
      const calculatedFixedCosts = calculateTotalFixedCosts(fetchedFixedCosts)
      const calculatedExtraordinaryCosts = calculateTotalExtraordinaryCosts(fetchedExtraordinaryCosts)
      let total = calculatedProjectTotalCosts + calculatedOtherIncome - calculatedFixedCosts - calculatedExtraordinaryCosts;
      setTotalCosts(total)
    }
  };

  const calculateTotalOtherIncomes = (otherIncomes) => {
    let total = 0;
    otherIncomes.forEach(income => {
      total += parseFloat(income.amount); // Parse the price as a float
    });
    return total
  };

  return (
    <>
      {projectTotalCostsLoaded && fixedCostsLoaded && extraordinaryCostsLoaded && totalCostsLoaded && totalCosts.length != [] ?
        <>
          <div className="panel centerFlex flexDirectionColumn">
            <div className="centerFlex fullWidth flexDirectionRow">
              <div className="panel centerFlex fullWidth flexDirectionColumn">
                &nbsp;
                <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                  <h3 className="greenProjectTotalCosts">{projectTotalCosts.reduce((acc, curr) => acc + curr.totalCosts, 0).toFixed(2)} €</h3>
                </Confidental>
                  &nbsp;
                <h3>Income</h3>
                &nbsp;
              </div>

              <div className="panel centerFlex textIcon flexDirectionColumn">
                <h3>+</h3>
              </div>

              <div className="panel centerFlex fullWidth flexDirectionColumn">
                &nbsp;
                <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                  <h3 className="greenProjectTotalCosts">{calculateTotalOtherIncomes(otherIncomes)}€ </h3>
                </Confidental>
                &nbsp;
                <h3>Total</h3>
                &nbsp;
              </div>

              <div className="panel centerFlex textIcon flexDirectionColumn">
                <h3>-</h3>
              </div>

              <div className="panel centerFlex fullWidth flexDirectionColumn">
                &nbsp;
                <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                  <h3 className="redProjectTotalCosts">{calculateTotalFixedCosts(fixedCosts)} €</h3>
                </Confidental>
                &nbsp;
                <h3>Fixed Costs</h3>
                &nbsp;
              </div>

              <div className="panel centerFlex textIcon flexDirectionColumn">
                <h3>-</h3>
              </div>

              <div className="panel  centerFlex fullWidth flexDirectionColumn">
                  &nbsp;
                  <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                    <h3 className="redProjectTotalCosts">{calculateTotalExtraordinaryCosts(extraordinaryCosts)}€ </h3>
                  </Confidental>
                  &nbsp;
                  <h3>Extraordinary Costs</h3>
                  &nbsp;
              </div>
            </div>

            <div className="centerFlex fullWidth flexDirectionRow">
              <div className="panel centerFlex fullWidth flexDirectionColumn">
                  &nbsp;
                  <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                    <h3 className={totalCosts < 0 ? "redProjectTotalCosts" : "greenProjectTotalCosts"}>{totalCosts.toFixed(2)}€ </h3>
                  </Confidental>
                  &nbsp;
                  <h3>Total</h3>
                  &nbsp;
              </div>
            </div>           
          </div>
        </> 
      :
        null
      }
    </>
  );
}

export default TotalCostsOverview;
