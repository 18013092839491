import React, { useState, useEffect } from "react";
import { fetchOtherIncome, addOtherIncome, deleteOtherIncome } from "../functions/otherIncome";
import Confidental from './Confidental.js'
const updateInterval = process.env.REACT_APP_updateInterval || 60000

function OtherIncome() {
  const [otherIncomes, setOtherIncomes] = useState([]);
  const [otherIncomesLoaded, setOtherIncomesLoaded] = useState([]);
  const [newIncomeTitle, setNewIncomeTitle] = useState("");
  const [newIncomeAmount, setNewIncomeAmount] = useState("");

  useEffect(() => {
    fetchOtherIncomeData();

    const interval = setInterval(() => {
      // Your function logic here
      fetchOtherIncomeData();
    }, updateInterval); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(interval);

  }, []);

  const fetchOtherIncomeData = () => {
    fetchOtherIncome()
      .then(data => {
        setOtherIncomes(data);
        setOtherIncomesLoaded(true);
      })
      .catch(error => {
        console.error("Error fetching other income data:", error);
      });
  };

  const handleAddOtherIncome = () => {
    if (isNaN(newIncomeAmount)) {
      alert("Please enter a valid number for the income amount.");
      return;
    }
    addOtherIncome(newIncomeTitle, newIncomeAmount)
      .then(() => {
        fetchOtherIncomeData();
        setNewIncomeTitle("");
        setNewIncomeAmount("");
      })
      .catch(error => {
        console.error("Error adding other income:", error);
      });
  };

  const handleDeleteOtherIncome = (id) => {
    const confirmation = window.confirm("Are you sure you want to delete this income?");
    if (confirmation) {
      deleteOtherIncome(id)
        .then(() => {
          fetchOtherIncomeData();
        })
        .catch(error => {
          console.error("Error deleting other income:", error);
        });
    }
  };

  const calculateTotalOtherIncomes = (otherIncomes) => {
    let total = 0;
    otherIncomes.forEach(income => {
      total += parseFloat(income.amount); // Parse the price as a float
    });
    return total;
  };

  return (
    <div className="wrapper">
      <div className="panel fullHeightMinus80Padding">
        {console.log(otherIncomes)}
        {otherIncomesLoaded && otherIncomes.map ?
          <div className="panelContent">
            <h2>Other Income</h2>
            <input
              type="text"
              value={newIncomeTitle}
              onChange={(e) => setNewIncomeTitle(e.target.value)}
              placeholder="Title"
            />
            <input
              type="text"
              value={newIncomeAmount}
              onChange={(e) => setNewIncomeAmount(e.target.value)}
              placeholder="Amount"
            />
            <button className="buttonTransparent" onClick={handleAddOtherIncome}>Add Income</button>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {otherIncomes.map(income => (
                  <tr key={income.id}>
                    <td>{income.id}</td>
                    <td>{income.title}</td>
                    <td><Confidental hide={localStorage.getItem("hideConfidental") == "true"}>{income.amount}</Confidental></td>
                    <td>
                      <button className="buttonTransparent" onClick={() => handleDeleteOtherIncome(income.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="panel  centerFlex flexDirectionColumn totalPrice">
              &nbsp;
              <Confidental hide={localStorage.getItem("hideConfidental") == "true"}>
                <h3 className="greenProjectTotalCosts">{calculateTotalOtherIncomes(otherIncomes)}€ </h3>
              </Confidental>
              &nbsp;
              <h3>Total</h3>
              &nbsp;
            </div>
            
          </div>
        :
          null
      }
    </div>
  </div>
  );
}

export default OtherIncome;
