////////////////////////////////////////////////////
// INCLUDE NECESSARY PACKAGES
////////////////////////////////////////////////////
import React, { useRef } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM from 'react-dom'
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/i18n.js";
import IncomeOverviewSite from './sites/IncomeOverviewSite.js';
import HaloComponent from './components/HaloComponent';
import FixedCosts from './components/FixedCosts.js';
import Layout from './components/Layout';
import IncomeOverview from './components/IncomeOverview.js';
import TotalCostsOverview from './components/TotalCostsOverview.js';
import HomeSite from './sites/HomeSite.js';
import LoginSite from './sites/LoginSite.js';
import InstallSite from './sites/InstallSite.js';
import { FaTriangleExclamation } from "react-icons/fa6";

////////////////////////////////////////////////////
// IMPORT CSS FILES
////////////////////////////////////////////////////
require('./general.css')
const defaultConfig = require("./configs/defaultConfig")

////////////////////////////////////////////////////
// DEFINE VARIABLES
////////////////////////////////////////////////////
const username = localStorage.getItem("username");
const apiUrl = process.env.REACT_APP_apiUrl
const apiToken = localStorage.getItem('apiToken');
const loginApiToken = defaultConfig.loginApiToken;

////////////////////////////////////////////////////
// DEFINE THE TWO OPTIONS OF COMPONENTS TO RENDER
////////////////////////////////////////////////////
const showError = (errorMessage) => {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <React.StrictMode>
              <div className="centerXandY">
                <div className="panel centerFlex">
                  <div className="panelHeader">
                    <h1>Error</h1>
                    <div className="spacer20px"></div>
                    <FaTriangleExclamation size={"6x"}/>
                  </div>
                  <div className="panelContent">
                    <table>
                        <tr>
                            <td>Error Code:</td>
                            <td>{JSON.parse(errorMessage).code}</td>
                        </tr>
                        <tr>
                            <td>Address:</td>
                            <td>{JSON.parse(errorMessage).address}</td>
                        </tr>
                        <tr>
                            <td>Port:</td>
                            <td>{JSON.parse(errorMessage).port}</td>
                        </tr>
                        <tr>
                            <td>Complete Error:</td>
                            <td>{errorMessage}</td>
                        </tr>
                    </table>
                  </div>
                </div>
              </div>
            </React.StrictMode>
        </I18nextProvider>,

        document.getElementById('root')
    )
}

const showLogin = () => {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <React.StrictMode>
                <LoginSite token={apiToken}/>
            </React.StrictMode>
        </I18nextProvider>,

        document.getElementById('root')
    )
}

const showInstall = () => {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <React.StrictMode>
                    <InstallSite />
            </React.StrictMode>
        </I18nextProvider>,

        document.getElementById('root')
    )
}

const showSite = () => {
    ReactDOM.render(
      <React.StrictMode> {/* Wrap entire App with StrictMode */}
        <I18nextProvider i18n={i18n}>
          {/* <HaloComponent forwardedRef={haloRef} backgroundColor="#000000" baseColor="#ff0000" amplitudeFactor="0" speed="0"/> */}
          <BrowserRouter> {/* Move BrowserRouter outside of StrictMode */}
              <Routes>
                <Route path="/" element={<Layout><HomeSite /></Layout>}></Route>
                <Route path="/login" element={<LoginSite />}></Route>
                <Route path="/snippet" element={<IncomeOverview showFilter={false} />}></Route>
                <Route path="/total" element={<TotalCostsOverview />}></Route>
              </Routes>
          </BrowserRouter>
        </I18nextProvider>
    </React.StrictMode>,
          document.getElementById('root')
    );
}

////////////////////////////////////////////////////
// DEFINE checkApiToken FUNCTION WHICH WILL DECIDE
// WHICH OF THE TWO COMPONENTS WILL RENDER
////////////////////////////////////////////////////
const checkApiToken = async () => {
    console.log("CHEEEEECK")
    console.log(`${apiUrl}/login/checkApiToken`)
    await fetch(`${apiUrl}/login/checkApiToken`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `${loginApiToken}`,
        "Access-Control-Request-Headers": "Authorization, Content-Type, Origin",
        "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify({"username": username, "apiToken": apiToken})
    })
    .then(data => data.json())
    .then(data => {
        if ( data.installDone == false && data.error.code != "ECONNREFUSED" ) {
            showInstall()
        }
        else if ( data.error ) {
            showLogin()
            // showError(JSON.stringify(data.error))
        }
        else if ( data.message && data.installDone == true ) {
            showLogin()
        }
        else if ( data.authenticated == true && data.installDone == true) {
            showSite()
        }
    })
}


////////////////////////////////////////////////////
// RUN CHECK AUTH TOKEN
////////////////////////////////////////////////////
async function main() {
    await checkApiToken()
}

main()
////////////////////////////////////////////////////
// DUNNO WHAT THIS IS... GOOGLE IT LATER
////////////////////////////////////////////////////
// If you want to start measuring performance in your Home, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
