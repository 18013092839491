import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    "en-EN": {
      translations: {
        "Introduction": "Introductionxxx",
        "Containers": "Containers",
        "App Store": "App Store",
        "Apps": "Apps",
        "Admin": "Admin",
        "Configure": "Configure",
        "Install": "Install",
        "Reconfigure": "Reconfigure",
        "Abort": "Abort",
        "Error while retrieving data from API": "Error while retrieving data from API",
        "List loading": "List loading",
        "Stats loading": "Stats loading",
        "Template Name": "Template Name",
        "Delete": "Delete",
        "User Administration": "User Administration",
        "Create New User": "Create New User",
        "ID": "ID",
        "Username": "Username",
        "Firstname": "Firstname",
        "Lastname": "Lastname",
        "E-Mail": "E-Mail",
        "Roles": "Roles",
        "Password": "Password",
        "All Users": "All Users",
        "Logged in as": "Logged in as",
        "Change Password": "Change Password",
        "Change": "Change",
        "Change Information": "Change Informations",
        "Login": "Login",
        "Login sucessfull": "Login sucessfull",
        "Users": "User",
        "Start": "Start",
        "Stop": "Stop",
        "Project": "Project",
        "CPU Usage": "CPU Usage",
        "RAM Usage": "RAM Usage",
        "Logs": "Logs",
        "Collapse": "Collapse",
        "Search...": "Search...",
        "Status": "Status",
        "Search Results": "Search Results",
        "Profile": "Profile",
        "Installation started": "Installation started",
        "App installed": "App installed",
        "Container deleted": "Container deleted",
        "App deleted": "App deleted",
        "Create new User": "Create new User",
        "Passwords don't match": "Passwords don't match",
        "Change Password": "Change Password",
        "Do you really want to delete this app?": "Do you really want to delete this app?",
        "App not deleted!": "App not deleted!",
        "Current Password": "Current Password",
        "New Password": "New Password",
        "New Password confirmation": "New Password confirmation",
        "Domain": "Domain",
        "Open App": "Open App",
        "Delete User": "Delete User",
        "Roles": "Roles",
        "Do you really want to delete this User?": "Do you really want to delete this User?",
        "User not deleted!": "User not deleted!",
        "Do you really want to delete this Domain?": "Do you really want to delete this Domain?",
        "Delete Domain with ID ": "Delete Domain with ID ",
        "Domain not deleted!": "Domain not deleted!",
        "Domain Administration": "Domain Administration",
        "Create New Domain": "Create New Domain",
        "Second Level Domain": "Second Level Domain",
        "Top Level Domain": "Top Level Domain",
        "All Domains": "All Domains",
        "Sub Domains": "Sub Domains",
        "Do you really want to delete this Data Folder?": "Do you really want to delete this Data Folder?",
        "File is to large. Maximum size is 9MB!": "File is to large. Maximum size is 9MB!",
        "App": "App",
        "Name": "Name",
        "Comment": "Comment",
        "Date": "Date",
        "Backup Name": "Backup Name",
        "Backup Comment": "Backup Kommentar",
        "Create Backup": "Create Backup",
        "Creating Backup": "Creating Backup",
        "Backup created with ID: ": "Backup created with ID: ",
        "Backup restored from Backup with ID: ": "Backup restored from Backup with ID: ",
        "Do you really want to restore this App from Backup with ID: ": "Do you really want to restore this App from Backup with ID: ",
        "App Data deleted": "App Data deleted",
        "App Data NOT deleted": "App Data not deleted",
        "Backup NOT restored": "Backup NOT restored",
        "Do you really want to DELETE the Backup with ID: ": "Do you really want to DELETE the Backup with ID: ",
        "Backup successfully deleted": "Backup successfully deleted",
        "Intro done": "Intro done",
        "Language": "Language",
        "Welcome to sysgrid": "Welcome to sysgrid",
        "Sysgrid Commander is your AppStore for installing any WebApp you need to start a successfull and organized Business!": "Sysgrid Commander is your AppStore for installing any WebApp you need to start a successfull and organized Business!",
        "In the following we will install the > sysgrid Commander < which contains the AppStore, an overview of all installed Apps and many more features.": "In the following we will install the > sysgrid Commander < which contains the AppStore, an overview of all installed Apps and many more features.",
        "Install Database": "Install Database",
        "Install Tables": "Install Tables",
        "Create Admin User": "Create Admin User",
        "Start Installation": "Start Installation",
        "Go to Login": "Go to Login",
        "The Credentials of the new Admin User are": "The Credentials of the new Admin User are",
        "Please write them down since they are only displayed once!": "Please write them down since they are only displayed once!",
        "Username": "Username",
        "Password": "Password",
        "No Search Results found": "No Search Results found",
        "This is Home Page, where you will see an overview of Informations, like last Installed Apps, News from the sysgrid Headquarters and more.": "This is Home Page, where you will see an overview of Informations, like last Installed Apps, News from the sysgrid Headquarters and more.",
        "This is your AppStore, from where you can install new Software/Webapps with one click.": "This is your AppStore, from where you can install new Software/Webapps with one click.",
        "Apps which you installed over the AppStore, will be displayed here.": "Apps which you installed over the AppStore, will be displayed here.",
        "This is your Admin Area where you can create Users and Backups and where you can see and configure more details about the System.": "This is your Admin Area where you can create Users and Backups and where you can see and configure more details about the System.",
        "Here you can create App Backups and Recover Backups for specific Apps.": "Here you can create App Backups and Recover Backups for specific Apps.",
        'This are the Domains which should be available when installing a new App. A Domain is the Webaddress under which a Website/Webapp is reachable. For example: sysgrid.de A Subdomain is the part before the domain. For example: "cloud.sysgrid.de". In this Case "cloud" is the Subdomain and "sysgrid.de" is the Domain.': 'This are the Domains which should be available when installing a new App. A Domain is the Webaddress under which a Website/Webapp is reachable. For example: sysgrid.de A Subdomain is the part before the domain. For example: "cloud.sysgrid.de". In this Case "cloud" is the Subdomain and "sysgrid.de" is the Domain.',
        "Here you find a list with the Data Folders for an App and you are able to delete them if necessary. A Datafolder contains all the User data from an App. For example: Database Files, Uploaded Files and so on. Once deleted, a datafolder is unrecoverable.": "Here you find a list with the Data Folders for an App and you are able to delete them if necessary. A Datafolder contains all the User data from an App. For example: Database Files, Uploaded Files and so on. Once deleted, a datafolder is unrecoverable.",
        "The Apps and Databases are running in Containers on the Host Server. Here you find an overview of all running Containers with there technical name, and you are able to Start or Stop them, aswell as delete them.": "The Apps and Databases are running in Containers on the Host Server. Here you find an overview of all running Containers with there technical name, and you are able to Start or Stop them, aswell as delete them.",
        "Here you are able to create new Users, Edit existing ones, set new Passwords for Users or edit the User Roles.": "Here you are able to create new Users, Edit existing ones, set new Passwords for Users or edit the User Roles.",
        "Here you can Search for whatever you want. The Search includes News Items, Apps from the AppStore and Apps you have installed.": "Here you can Search for whatever you want. The Search includes News Items, Apps from the AppStore and Apps you have installed.",
        "Here you can set your preffered Language.": "Here you can set your preffered Language.",
        "Here you can see the latest notifications which were shown on the System your are opened the Sysgrid Commander.": "Here you can see the latest notifications which were shown on the System your are opened the Sysgrid Commander.",
        "Here you can view and edit your own User Informations and also Logout.": "Here you can view and edit your own User Informations and also Logout.",
        "Informations about this Menupoint": "Informations about this Menupoint",
        "Informations about the Menupoints": "Informations about the Menupoints",
        "Intro Info Box activated": "Intro Info Box activated",
        "Intro Info Box deactivated": "Intro Info Box deactivated",
        "This Icon will turn the Intro Info Boxes on and off.": "This Icon will turn the Intro Info Boxes on and off.",
        "This is Home Page, where you will see an overview of Informations, like last Installed Apps, News from the sysgrid Headquarters and more.":"This is Home Page, where you will see an overview of Informations, like last Installed Apps, News from the sysgrid Headquarters and more.",
        "Example": "Example",
        "Configuration": "Configuration",
        "Close": "Close",
        "Do this in the Menubar above to change the language": "Do this in the Menubar above to change the language",
        "Configure default Domain": "Configure default Domain",
        "All": "All",
        "Here you can configure your VPN Certificates.": "Here you can configure your VPN Certificates.",
        "VPN Configuration": "VPN Configuration",
        "Wireguard Config": "Wireguard Config",
        "New Config": "New Config",
        "Config successfully created": "Config successfully created",
        "Delete Config for": "Delete Config for",
        "Config created for Client": "Config created for Client",
        "Create new VPN Config": "Create new VPN Config",
        "Clientname must not be empty": "Clientname must not be empty",
        "Open App in VPN": "Open App in VPN",
        "More": "More",
        "Successfully recreated Bind DNS Config": "Successfully recreated Bind DNS Config",
        "Successfully recreated Reverseproxy Config": "Successfully recreated Reverseproxy Config",
        "App started": "App started",
        "App restarted": "App restarted",
        "App stopped": "App stopped",
        "Do you really want to start this app?": "Do you really want to start this app?",
        "Do you really want to restart this app?": "Do you really want to restart this app?",
        "Do you really want to stop this app?": "Do you really want to stop this app?",
        "App started": "App started",
        "App restarted": "App restarted",
        "App stopped": "App stopped",
        "App not started": "App not started",
        "App not restarted": "App not restarted",
        "App not stopped": "App not stopped",
        "Delete App Data": "Delete App Data",
        "VPN Domain and Top Level Domain must not be the same as an Domain configured in the System": "VPN Domain and Top Level Domain must not be the same as an Domain configured in the System",
        "Do you really want to delete this VPN Config?": "Do you really want to delete this VPN Config?",
        "No VPN Configuration created!": "Keine VPN Konfiguration hinzugefügt!",
        "Error Installing App": "Error Installing App",
        "Install App": "Install App",
        "App will be deleted": "App will be deleted",
        "Changing Password": "Changing Password",
        "Password changed": "Password changed",
        "Password not changed": "Password not changed",
        "Update AppStore": "Update Store",
        "ID": "ID",
        "IP Address": "IP Address",
        "Reason": "Reason",
        "Blocked until": "Blocked until",
        "Date created": "Date created"
      }
    },
    "de-DE": {
      translations: {
        "Introduction": "Introductionxxx",
        "Containers": "Containers",
        "App Store": "App Store",
        "Apps": "Apps",
        "Admin": "Admin",
        "Configure": "Konfigurieren",
        "Install": "Installieren",
        "Reconfigure": "Neu konfigurieren",
        "Abort": "Abbrechen",
        "Error while retrieving data from API": "Fehler beim Beziehen der Daten von der API",
        "List loading": "Liste lädt",
        "Stats loading": "Stats laden",
        "App Name": "App Name",
        "Delete": "Löschen",
        "User Administration": "Benutzer Administration",
        "Create New User": "Neuen Nutzer erstellen",
        "ID": "ID",
        "Username": "Benutzername",
        "Firstname": "Vorname",
        "Lastname": "Nachname",
        "E-Mail": "E-Mail",
        "Roles": "Rollen",
        "Password": "Passwort",
        "All Users": "Alle Benutzer",
        "Logged in as": "Eingeloggt als",
        "Change Password": "Passwort ändern",
        "Change": "Ändern",
        "Change Information": "Informationen ändern",
        "Login": "Login",
        "Login sucessfull": "Erfolgreich eingeloggt",
        "Users": "Benutzer",
        "Start": "Starten",
        "Stop": "Stoppen",
        "Project": "Projekt",
        "CPU Usage": "CPU Auslastung",
        "RAM Usage": "RAM Auslastung",
        "Logs": "Logs",
        "Collapse": "Einklappen",
        "Search...": "Suche...",
        "Status": "Status",
        "Search Results": "Such Ergebnisse",
        "Profile": "Profil",
        "Installation started": "Installation gestartet",
        "App installed": "App installiert",
        "Container deleted": "Container gelöscht",
        "App deleted": "App gelöscht",
        "Create new User": "Erstelle neuen Benutzer",
        "Passwords don't match": "Passwörter stimmen nicht überein",
        "Change Password": "Password wird geändert",
        "Do you really want to delete this app?": "Möchten Sie diese App wirklich löschen?",
        "App not deleted!": "App nicht gelöscht!",
        "Current Password": "Aktuelles Passwort",
        "New Password": "Neues Passwort",
        "New Password confirmation": "Neues Passwort bestätigen",
        "Domain": "Domain",
        "Open App": "App öffnen",
        "Delete User": "Lösche Benutzer",
        "Roles": "Rollen",
        "Do you really want to delete this User?": "Mächten Sie diesen Benutzer wirklich löschen?",
        "User not deleted!": "Nutzer nicht gelöscht!",
        "Do you really want to delete this Domain?": "Möchten Sie diese Domain wirklich löschen?",
        "Delete Domain with ID ": "Lösche Domain mit der ID ",
        "Domain not deleted!": "Domain nicht gelöscht!",
        "Domain Administration": "Domain Administration",
        "Create New Domain": "Erstelle Neue Domain",
        "Second Level Domain": "Second Level Domain",
        "Top Level Domain": "Top Level Domain",
        "All Domains": "Alle Domains",
        "Sub Domains": "Sub Domains",
        "Do you really want to delete this Data Folder?": "Möchten Sie diese App Daten wirklich löschen?",
        "File is to large. Maximum size is 9MB!": "Datei ist zu groß. Maximale Größe ist 9MB!",
        "Backup App": "Backup App",
        "Name": "Name",
        "Comment": "Kommentar",
        "Date": "Datum",
        "Backup Name": "Backup Name",
        "Backup Comment": "Backup Comment",
        "Create Backup": "Backup erstellen",
        "Creating Backup": "Backup wird erstellt",
        "Backup created with ID: ": "Backup erstellt unter der ID: ",
        "Backup successfully restored from Backup with ID: ": "Backup erfolgreich wiederhergestellt von Backup mit ID: ",
        "Do you really want to restore this App from Backup with ID: ": "Möchten Sie diese App wirklich wiederherstellen vom Backup mit der ID: ",
        "App Data deleted": "App Daten gelöscht",
        "App Data NOT deleted": "App Daten NICHT gelöscht",
        "Backup NOT restored": "Backup NICHT wiederhergestellt",
        "Do you really want to DELETE the Backup with ID: ": "Möchten Sie das Backup wirklich löschen mit der ID: ",
        "Backup successfully deleted": "Backup erfolgreich gelöscht",
        "Backup NOT deleted": "Backup NICHT gelöscht",
        "Intro done": "Intro abgeschlossen",
        "Language": "Sprache",
        "Welcome to sysgrid": "Willkommen bei sysgrid",
        "Sysgrid Commander is your AppStore for installing any WebApp you need to start a successfull and organized Business!": "Sysgrid Commander ist dein AppStore zur einfachen installation aller WebApps welche benötigt werde um ein erfolgreiches und organisiertes Unternehmen zu starten!",
        'In the following we will install the "sysgrid Commander" which contains the AppStore, an overview of all installed Apps and many more usefull features.': 'Im folgenden wird der "sysgrid Commander" installiert, welcher den AppStore, eine Übersicht aller installierter Apps und viele weitere nützliche Funktionen beinhaltet.',
        "Install Database": "Installiere Database",
        "Install Tables": "Installiere Tables",
        "Create Admin User": "Erstelle Admin Nutzer",
        "Start Installation": "Installation starten",
        "Go to Login": "Weiter zum Login",
        "The Credentials of the new Admin User are": "Die Zugangsdaten des Admin Nutzers sind",
        "Please write them down since they are only displayed once!": "Bitte notiere diese, da sie nur einmal angezeigt werden!",
        "Username": "Benutzername",
        "Password": "Passwort",
        "No Search Results found": "Keine Suchergebnisse gefunden",
        "This is Home Page, where you will see an overview of Informations, like last Installed Apps, News from the sysgrid Headquarters and more.": "Dies ist die Startseite/Homepage, auf welcher Sie eine Übersicht verschiedener Informationen wie beispielsweise die zuletzt  Installierten Apps, Neuigkeiten vom sysgrid Headquarter und vieles mehr finden.",
        "This is your AppStore, from where you can install new Software/Webapps with one click.": "Dies ist der AppStore von welchem aus neue Software/WebApps mit einem Klick installiert werden können.",
        "Apps which you installed over the AppStore, will be displayed here.": "Apps welche über den AppStore installiert wurden, werden hier angezeigt.",
        "This is your Admin Area where you can create Users and Backups and where you can see and configure more details about the System.": "Dies ist der Admin Bereich, in welchem Sie neue Nutzer und Backups erstellen können und weitere Details über das System finden und anpassen können.",
        "Here you can create App Backups and Recover Backups for specific Apps.": "Hier können Sie App Backups erstellen und Wiederherstellen.",
        'This are the Domains which should be available when installing a new App. A Domain is the Webaddress under which a Website/Webapp is reachable. For example: sysgrid.de. A Subdomain is the part before the domain. For example: "cloud.sysgrid.de". In this Case "cloud" is the Subdomain and "sysgrid.de" is the Domain. The Toplevel Domain is the ending. For Example ".de" or ".com".': 'Hier können Sie die Domains einsehen und konfigurieren, welche zur Auswahl stehen sollen, wenn eine neue App installiert wird. Eine Domain ist die Webadresse unter welcher eine Website/Webapp erreichbar ist. Beispielsweise: "cloud.sysgrid.de". In diesem Fall ist "cloud" die Subdomain und "sysgrid.de" die Domain. Die Toplevel Domain ist die Endung. Zum Beispiel ".de" oder ".com".',
        "Here you find a list with the Data Folders for an App and you are able to delete them if necessary. A Datafolder contains all the User data from an App. For example: Database Files, Uploaded Files and so on. Once deleted, a datafolder is unrecoverable.": "Hier finden Sie eine Liste aller Daten Ordner für die Apps, und können diese löschen falls notwendig. Ein Daten Ordner beinhaltet alle Nutzerdaten einer App. Beispielsweise: Datenbankdateien, hochgeladene Dateien und so weiter. Wenn Sie einen Daten Ordner löschen, können Sie diesen nicht wiederherstellen.",
        "The Apps and Databases are running in Containers on the Host Server. Here you find an overview of all running Containers with there technical name, and you are able to Start or Stop them, aswell as delete them.": "Die Apps und Datenbanken laufen auf dem Server in Containern. Hier finden Sie eine Übersicht aller laufenden Container inklusive deren technischer Namen. Außerdem können Sie diese Starten, Stoppen oder Löschen.",
        "Here you are able to create new Users, Edit existing ones, set new Passwords for Users or edit the User Roles.": "Hier können Sie neue Nutzer erstellen, bestehende Nutzer editieren, Passwörter zurücksetzen und Nutzerrollen anpassen.",
        "Here you can Search for whatever you want. The Search includes News Items, Apps from the AppStore and Apps you have installed.": "Hier können Sie nach verschiedenen Inhalten suchen. Die Suche beinhaltet News/Neuigkeiten, Apps aus dem AppStore und installierte Apps.",
        "Here you can set your preffered Language.": "Hier können SIe die bevorzugte Sprache einstellen.",
        "Here you can see the latest notifications which were shown on the System your are opened the Sysgrid Commander.": "Hier finden Sie die zuletzt angezeigten Benachrichtigungen, welche von sysgrid Commander auf Ihrem System angezeigt wurden.",
        "Here you can view and edit your own User Informations and also Logout.": "Hier können Sie Ihre eigenen Benutzerinformationen einsehen und anpassen und sich ausloggen.",
        "Informations about this Menupoint": "Informationen über diesen Menüpunkt",
        "Informations about the Menupoints": "Informationen über die Menüpunkte",
        "Intro Info Box activated": "Intro Info Box aktiviert",
        "Intro Info Box deactivated": "Intro Info Box deaktiviert",
        "This Icon will turn the Intro Info Boxes on and off.": "Dieses Icon schaltet die Intro info Boxen ein und aus.",
        "This is Home Page, where you will see an overview of Informations, like last Installed Apps, News from the sysgrid Headquarters and more.":"Dies ist die Startseite/HomePage, auf welcher eine Informationsübersicht wie Beispielsweise die zuletzt installierten Apps zu finden ist. Außerdem findest du hier News aus den sysgrid Headquarters.",
        "Example": "Beispiel",
        "Configuration": "Konfiguration",
        "Close": "Schließen",
        "Do this in the Menubar above to change the language": "Machen Sie dies in der Menubar oben um die Sprache zu ändern.",
        "Configure default Domain": "Standard Domain einrichten",
        "All": "Alle",
        "Here you can configure your VPN Certificates.": "Hier können Sie Ihre VPN Zertifikate verwalten.",
        "VPN Configuration": "VPN Konfiguration",
        "Wireguard Config": "Wireguard Konfiguration",
        "New Config": "Neue Konfiguration",
        "Config successfully created": "Konfiguration wurde erfolgreich erstellt",
        "Delete Config for": "Lösche Konfiguration für",
        "Config created for Client": "Konfiguration erstellt für Client",
        "Create new VPN Config": "Erstelle neue VPN Konfiguration",
        "Clientname must not be empty": "Client Name darf nicht leer sein",
        "Open App in VPN": "Öffne App über VPN",
        "More": "Mehr",
        "Successfully recreated Bind DNS Config": "Bind DNS COnfig erfolgreich erstellt",
        "Successfully recreated Reverseproxy Config": "Reverseproxy Config erfolgreich erstellt",
        "App started": "App  gestarted",
        "App restarted": "App  neugestarted",
        "App stopped": "App  gestoppt",
        "Do you really want to start this app?": "Möchten Sie diese App wirklich starten?",
        "Do you really want to restart this app?": "Möchten Sie diese App wirklich restarten?",
        "Do you really want to stop this app?": "Möchten Sie diese App wirklich stoppen?",
        "App started": "App  gestarted",
        "App restarted": "App  neugestartet",
        "App stopped": "App  gestoppt",
        "App not started": "App nicht gestarted",
        "App not restarted": "App nicht neugestartet",
        "App not stopped": "App nicht gestoppt",
        "Delete App Data": "App Daten löschen",
        "VPN Domain and Top Level Domain must not be the same as an Domain configured in the System": "VPN Domain und Top Level Domain dürfen keiner der im System hinterlegten Domains sein",
        "Do you really want to delete this VPN Config?": "Möchtest du diese VPN Konfiguration wirklich löschen?",
        "No VPN Configuration created!": "Keine VPN Konfiguration erstellt!",
        "Error Installing App": "Error beim Installieren der App",
        "Install App": "Installiere App",
        "App will be deleted": "App wird gelöscht",
        "Changing Password": "Passwort ändern",
        "Password changed": "Passwort geändert",
        "Password not changed": "Passwort nicht geändert",
        "Update AppStore": "AppStore wird geupdated",
        "IP Address": "IP Addresse",
        "Reason": "Grund",
        "Blocked until": "Blockiert bis",
        "Date created": "Erstelldatum"
      }
    },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
}});

export default i18n;
