const config = require("../configs/defaultConfig")
const apiToken = localStorage.getItem("apiToken")
const username = localStorage.getItem("username")
const apiUrl = process.env.REACT_APP_apiUrl

export const fetchFixedCosts = async () => {
    return await fetch(`${apiUrl}/fixedcosts/`, {
      method: 'GET', // or 'PUT'
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${apiToken}`,
          'Username': `${username}`,
          "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
      }
    })
    .then((res) => {
            return res.json()
    })
    .then((json) => {
        //json to array for every object in object enVariables
        if(json.error){
            return(json.error)
        }
        else {
          return json
        }
    })
    .catch( err => {
      return(err)
    })
  };


export const addFixedCost = async (newCostTitle, newCostPrice) => {
    try {
        await fetch(`${apiUrl}/fixedcosts/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiToken}`,
            'Username': `${username}`,
            "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
        },
        body: JSON.stringify({ 
                title: newCostTitle,
                price: newCostPrice 
            }),
        });
        fetchFixedCosts(); // Refresh fixed costs data
    } catch (error) {
        console.error("Error adding fixed cost:", error);
    }
};

export const deleteFixedCost = async (id, title, price) => {
    try {
        await fetch(`${apiUrl}/fixedcosts/delete`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiToken}`,
            'Username': `${username}`,
            "Access-Control-Request-Headers": "Authorization, Content-Type, Accept",
        },
        body: JSON.stringify({ 
            "id": id,
            "title": title,
            "price": price     
            }),
        });
        fetchFixedCosts(); // Refresh fixed costs data
    } catch (error) {
        console.error("Error deleting fixed cost:", error);
    }
};

  